body {
  background-color: #999999;
}

.container {
  min-height: 100vh;
}

.bg-image {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0px);
}

.camera {
  position: absolute;
  left: 50%;
  top: 2px;
  transform: translate(-48.5%, 0px);
}

#door-1 {
  position: absolute;
  left: 50%;
  top: 161px;
  transform: translate(-51.5%, 0px);
  z-index: -2;
  transition: 1s ease;
}

#door-2 {
  position: absolute;
  left: 50%;
  top: 313px;
  transform: translate(-50.5%, 0px);
  z-index: -1;
  transition: 1s ease;
}

.bg-white {
  background-color: #fff;
  z-index: -100;
  top: 50%;
  width: 500px;
  height: 400px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

#inside-door {
  position: absolute;
  left: 50%;
  top: 160px;
  transform: translate(-50.5%, 0px);
  display: none;
  color: black;
  height: 340px;
}

#inside-door main {
  position: relative;
  top: 50%;
  transform: translate(0px, -50%);
  text-align: center;
}

.logo {
  width: 400px;
}

.camera-wrap {
  height: 599px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.eye-scan-box {
  position: absolute;
  cursor: pointer;
  width: 150px;
  height: 70px;
  left: 50%;
  top: 320px;
  transform: translate(-270%, 0px);
  z-index: 10;
}

#eye-scan-image {
  display: none;
  position: absolute;
  cursor: pointer;
  left: 50%;
  top: 302px;
  transform: translate(-303%, 0px);
}

#eye-scan-gif {
  display: none;
  position: absolute;
  left: 50%;
  top: 185px;
  transform: translate(-277%, 0px);
  z-index: 20;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.gray-box {
  background-color: #999999;
  position: absolute;
  width: 100%;
  top: -10px;
  height: 13px;
  z-index: 10000;
}

.info {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 260%);
  height: 120px;
}

.info h1 {
  font-size: 19px;
  margin-bottom: 0px;
  margin-top: 5px;
}


@media screen and (max-width: 768px) {
  .bg-image {
    width: 381.6px;
  }

  .camera-wrap {
    height: 239.59px;
    max-width: 100%;
  }

  #inside-door {
    top: 63px;
    height: 140px;
  }

  #inside-door main p {
    margin: 0px;
    font-size: 11px;
  }

  #inside-door main button {
    font-size: 11px;
    padding: 2px 10px;
    min-width: initial;
  }

  #inside-door main .MuiButton-contained {
    margin-top: 9px;
  }
  
  .camera {
    width: 75.2px;
    top: 1px;
  }
  
  #door-1 {
    width: 174.4px;
    top: 64px;
  }
  
  #door-2 {
    width: 177.2px;
    top: 125px;
  }
  
  .eye-scan-box {
    position: absolute;
    cursor: pointer;
    width: 70px;
    height: 70px;
    left: 50%;
    top: 120px;
    transform: translate(-240%, 0px);
  }
  
  #eye-scan-image {
    width: 52.9px;
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 121px;
    transform: translate(-302%, 0px);
  }
  
  .bg-white {
    width: 300px;
    height: 200px;
    top: 130px;
  }
  
  #eye-scan-gif {
    width: 57.2px;
    left: 50%;
    top: 74px;
    transform: translate(-277%, 0px);
  }

  .logo {
    width: 150px;
  }

  .info {
    font-size: 13px;
    position: absolute;
    transform: translate(-50%, 210%);
    margin-bottom: 100px;
    height: 60px;
  }

  .info h1 {
    font-size: 12px;
  }

}